import { Component, OnInit, NgZone, Injectable } from '@angular/core';
import { DirectoryService } from '../services/directiry.service';
import { FileInfo } from './models/fileInfo';
import { AppRepository } from './repositore/app.repository';
const CLIENT_ID = "188877154290-0n9kfbl4qfa75383kb173o6071vlvbme.apps.googleusercontent.com";
const API_KEY = "AIzaSyDjbdAQcCRNiNHmd6D-L76n051FLv8pODU";
const DISCOVERY_DOCS = ["https://www.googleapis.com/discovery/v1/apis/drive/v3/rest"];
var SCOPES = 'https://www.googleapis.com/auth/drive';

@Injectable()
export class LogDriveComponent implements OnInit {

  googleAuth: gapi.auth2.GoogleAuth;


  constructor(
    private appRepository: AppRepository,
    private zone: NgZone,
    private directoryService: DirectoryService
  ) {
   }


  initClient() {
  console.log("--initClient--")
    return new Promise((resolve,reject)=>{
        gapi.load('client:auth2', () => {
            return gapi.client.init({
                apiKey: API_KEY,
                clientId: CLIENT_ID,
                discoveryDocs: DISCOVERY_DOCS,
                scope: SCOPES,
            }).then(() => {           
              console.log(".----------------.")        
                this.googleAuth = gapi.auth2.getAuthInstance();
                resolve();
            });
        });
    });
     
}

signIn() {
  console.log("-----signIn-1------");
  return this.googleAuth.signIn({
      prompt: 'consent'
  }).then((googleUser: gapi.auth2.GoogleUser) => {
    console.log("------signIn-2-----");
    this.directoryService.setDataUsuario(googleUser.getBasicProfile().getEmail())
  });
}


ngOnInit(): void {
  this.signIn();
}

get isSignedIn(): boolean {
  return this.googleAuth.isSignedIn.get();
}

get userData(){
  return this.googleAuth.currentUser;
}

signOut(): void {
  this.googleAuth.signOut();
}
}
