import { THIS_EXPR } from '@angular/compiler/src/output/output_ast';
import { Component, Input, NgZone, OnInit, ɵConsole } from '@angular/core';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { ActivatedRoute } from '@angular/router';
import { BreadCrumbSession } from 'src/app/infrastructure/breadcrumb.session';
import { BreadCrumbItem } from 'src/app/log-drive/models/breadCrumbItem';
import {
  BreadCrumbItemOption,
  OPTION_NEW_FOLDER,
  OPTION_UPLOAD_FILES,
} from 'src/app/log-drive/models/breadCrumbItemOption';
import {
  FileInfo,
  MIME_TYPE_FOLDER,
  MIME_TYPE_SHORTCUT,
} from 'src/app/log-drive/models/fileInfo';

@Component({
  selector: 'app-documents',
  templateUrl: './documents.component.html',
  styleUrls: ['./documents.component.scss'],
})
export class DocumentsComponent implements OnInit {
  breadCrumbItems: BreadCrumbItem[] = [];
  googleAuth: gapi.auth2.GoogleAuth;
  files: FileInfo[] = [];
  dataSource: FileInfo[] = [];
  nameDirectory: string = '';
  nameImg: string = '';
  idDirectory: string = '';
  idDirectoryData: string = '';

  constructor(
    private zone: NgZone,
    private bottomSheet: MatBottomSheet,
    private breadCrumbSession: BreadCrumbSession,
    private route: ActivatedRoute
  ) { }

  ngOnInit(): void {

    console.log("INICIO")
    this.idDirectoryData = this.route.snapshot.paramMap.get('id');

    if (this.idDirectoryData == 'Consejo-de-Administracion') {
      this.nameDirectory = 'Consejo de Administración';
      this.nameImg = 'conAdm.png';
      this.idDirectory = '1b1M4pq3D_BDVDC5Z7OAC3yQ5ZJpxvkwX';
    } else if (
      this.idDirectoryData == 'Comite-de-Auditoria-y-Practicas-Societarias'
    ) {
      console.log('2');
      this.nameDirectory = 'Comité de Auditoria y Practicas Societarias';
      this.nameImg = 'comAudPraSoc2.jpg';
      this.idDirectory = '1PoTBZi81GpOenyj_DKegWJRqlGB2Ee3G';
    } else if (this.idDirectoryData == 'Comite-de-Estrategia') {
      console.log('3');
      this.nameDirectory = 'Comité de Estrategia';
      this.nameImg = 'comEst.jpg';
      this.idDirectory = '1nlcxsQQq8M8nZNnXBQ93MXIEawhVRZTh';
    } else if (this.idDirectoryData == 'Direccion-General') {
      console.log('4');
      this.nameDirectory = 'Dirección General';
      this.nameImg = 'dirGen.jpg';
      this.idDirectory = '1wTss7whOPvs9uXaLI-U9xDicRUan_4mR';
    }else if (this.idDirectoryData == 'Asamblea-de-Accionistas') {
        console.log('4');
        this.nameDirectory = 'Asamblea de Accionistas';
        this.nameImg = 'asambleaAccionistas.jpg';
        this.idDirectory = '16eZuGTD9KvJU3p2LUqryeEIcu6gu_62B';
        
    }
    //this.refresh('root');
    this.getdirectorys()
    this.breadCrumbSession.init();
    this.breadCrumbItems = this.breadCrumbSession.items;
  }

  getdirectorys() {
    this.getFiles(this.idDirectory).then((res) => {
      this.zone.run(() => {
        console.log('Respuesta --->', res);
        this.files = res;
        this.dataSource = this.files;
        this.dataSource.sort((a, b) => a.Name.localeCompare(b.Name));
        this.breadCrumbSession.navigateTo(this.idDirectory, this.nameDirectory);
        this.breadCrumbItems = this.breadCrumbSession.items;
      });
    });
  }

  getFiles(folderId: string) {
    return gapi.client.drive.files
      .list({
        pageSize: 100,
        fields: 'nextPageToken, files(id, name, mimeType, modifiedTime, size)',
        q: `'${folderId}' in parents and trashed = false`,
      })
      .then((res) => {
        let files: FileInfo[] = [];
        res.result.files.forEach((file) =>
          files.push(FileInfo.fromGoogleFile(file))
        );
        return files;
      });
  }

  get isSignedIn(): boolean {
    return this.googleAuth.isSignedIn.get();
  }

  browse(file: FileInfo) {
    if (file.Id != 'root') {
      this.files.forEach((element) => {
        console.log("element----->",element)
        if (element.Id == '1TADRM2A65YZt8F0AcEVICtI9IQYPsOdC') {
          element.Id = "1gHw3fXkkks37_PBYY6YlqV1KCbgYZAlr";
        } else if (element.Id == '1Rig5rSe86mvtZMGZCGWwgNtS8r4H6Ezs') {
          element.Id = "1VhH1S1hUYLTHiQZntO2sanua4obt8TOo";
        } else if (element.Id == '1uw1vOzAqs9S97ncZjGtXm92czJKyAoHP') {
          element.Id = "13TcnxXoqSYaA4EpmHMVRGZin5CeDOy00";
        }
        else if (element.Id == '12f4rcaw76OJXpDu-SPyVCoW3j0MXEf8v') {
          element.Id = "1F-y1sCoiWudGa9sWd7THV3RQ6VJMWQnd";
        }
        else if (element.Id == '1vctaJjSMpr3nUVmWHKoe_ASiaHGAOAc5') {
          element.Id = "1F-y1sCoiWudGa9sWd7THV3RQ6VJMWQnd";
        }
        else if (element.Id == '1PKso6rYEE57Pp_b1d_2cPR7lj-I0NQOf') {
          element.Id = "1VhH1S1hUYLTHiQZntO2sanua4obt8TOo";
        }
      });
      if (file.IsFolder) {
        this.getFiles(file.Id).then((res) => {
          this.zone.run(() => {
            this.files = res;
            this.dataSource = this.files;
            this.dataSource.sort((a, b) => a.Name.localeCompare(b.Name));
            this.breadCrumbSession.navigateTo(file.Id, file.Name);
            this.breadCrumbItems = this.breadCrumbSession.items;
          });
        });
      } else if (file.IsShortcut) {
          this.getFiles(file.Id).then((res) => {
          this.zone.run(() => {
            this.files = res;
            this.dataSource = this.files;
            this.dataSource.sort((a, b) => a.Name.localeCompare(b.Name));
            this.breadCrumbSession.navigateTo(file.Id, file.Name);
            this.breadCrumbItems = this.breadCrumbSession.items;
          });
        });
      }
    }
  }

  download(fileId: string, minetype: any) {
    var link = '';
    return gapi.client.drive.files
      .get({
        fileId: fileId,
        fields: 'webContentLink',
      })
      .then((res) => {
        window.location.href = res.result.webContentLink;
        return res.result.webContentLink;
      });
  }

  viewFile(fileId: string) {
    console.log('fileid---VIEW--->', fileId);
    window.open('https://drive.google.com/file/d/' + fileId + '/view');
  }

  onSelectedItemChanged($event: BreadCrumbItem) {
    let fileInfo: FileInfo = new FileInfo();
    fileInfo.Name = $event.Name;
    if ($event.Id == '1gHw3fXkkks37_PBYY6YlqV1KCbgYZAlr') {
      console.log('$event.Id---------------->>>>', $event.Id);
      fileInfo.MimeType = MIME_TYPE_SHORTCUT;
      fileInfo.Id = '1gHw3fXkkks37_PBYY6YlqV1KCbgYZAlr';
      //fileInfo.Id = $event.Id;
    } else {
      fileInfo.MimeType = MIME_TYPE_FOLDER;
      fileInfo.Id = $event.Id;
    }
    this.browse(fileInfo);
  }

  onSelectedOptionChanged($event: BreadCrumbItemOption) {
    if ($event.Option === OPTION_NEW_FOLDER) {
      this.createNewFolder();
    } else if ($event.Option === OPTION_UPLOAD_FILES) {
      this.bottomSheet.open(DocumentsComponent, { data: $event.Data });
    }
  }

  createNewFolder() {
    /* var data: DialogOneInputData = new DialogOneInputData();
  data.DefaultInputText = "Untitled folder";
  data.Title = "New folder"
  const dialogRef = this.dialog.open(DialogOneInputComponent, {
      width: '250px',
      data: data
  });*/
  }
}
