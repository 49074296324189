import { Component, NgZone, OnInit } from '@angular/core';
import { LogDriveComponent } from '../log-drive/log-drive.component';
import { Router } from "@angular/router";

@Component({
  selector: 'app-start',
  templateUrl: './start.component.html',
  styleUrls: ['./start.component.scss']
})
export class StartComponent implements OnInit {

  constructor(
    private logDrive: LogDriveComponent,
    private router:Router,
    private zone: NgZone
    ) { }

  ngOnInit(): void {
  }


linkDirectory(dataDirectory: string){
  this.zone.run(() => {
    this.router.navigate(['/listDocuments', dataDirectory]);
  });
  
}
}
