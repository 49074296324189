import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { DocumentsComponent } from './Components/documents/documents.component';
import { LoggedInGuard } from './log-drive/loggedInGuard';
import { SignInComponent } from './log-drive/signin/signin.component';
import { StartComponent } from './start/start.component';

const routes: Routes = [
  
  { path: '', redirectTo: 'home', pathMatch: 'full'},
  { path: 'signin', component: SignInComponent},
  { path: 'home', component: StartComponent, canActivate: [LoggedInGuard]},
  { path: 'listDocuments/:id', component: DocumentsComponent},
  { path: 'listDocuments/Direccion-General', component: DocumentsComponent},
  { path: 'listDocuments/Consejo-de-Administracion', component: DocumentsComponent},
  { path: 'listDocuments/Comite-de-Estrategia', component: DocumentsComponent},
  { path: 'listDocuments/Comite-de-Auditoria-y-Practicas-Societarias', component: DocumentsComponent},
  { path: 'listDocuments/Asamblea-de-Accionistas', component: DocumentsComponent},
  { path: '**', component: StartComponent, canActivate: [LoggedInGuard]}

  /*{path: '',component:StartComponent,},
  {path:'login',component: SignInComponent},
  {path: '**',redirectTo:''}*/
  
];


@NgModule({
  imports: [RouterModule.forRoot(routes, {useHash: true})],
  exports: [RouterModule]
})
export class AppRoutingModule { }