import { Component, NgZone } from '@angular/core';

import { Router } from '@angular/router';
import { LogDriveComponent } from '../log-drive.component';

@Component({
  selector: 'signin',
  templateUrl: './signin.component.html',
  styleUrls: ['./signin.component.css'],
})
export class SignInComponent {
  constructor(
    private LogDriveComponent: LogDriveComponent,
    private router: Router,
    private zone: NgZone
  ) {}

  signIn() {
    this.LogDriveComponent.signIn().then(() => {
        if (this.LogDriveComponent.isSignedIn) {
        this.zone.run(() => {
          this.router.navigate(['/home']);
        });
      }else{
      }
    });
  }
}
