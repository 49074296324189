import { Component, NgZone } from '@angular/core';
import { Router } from '@angular/router';
import { LogDriveComponent } from './log-drive/log-drive.component';
import { DirectoryService } from './services/directiry.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent {
  title = 'SIC-app';

  users: any = '';

  constructor(
    private logDrive: LogDriveComponent,
    private router: Router,
    private directoryService: DirectoryService,
    private LogDriveComponent: LogDriveComponent,
    private zone: NgZone
  ) {
    this.directoryService.getDataUsuario().subscribe(
      (usuarios$) => {
        this.users = usuarios$;
      },
      (err) => {
        console.log('Error');
      }
    );
  }

  signOut() {
    this.users = '';
    this.directoryService.setDataUsuario('');
    this.logDrive.signOut();
    this.zone.run(() => {
    this.router.navigate(['/signin']);
    });
  }

  ngOnInit(): void {
    var isLoggedIn = this.logDrive.isSignedIn;
    if (isLoggedIn) {
      this.directoryService.setDataUsuario(
        this.logDrive.userData.get().getBasicProfile().getEmail()
      );
    }
  }

  signIn() {
    this.LogDriveComponent.signIn().then(() => {
        if (this.LogDriveComponent.isSignedIn) {
        this.zone.run(() => {
          this.router.navigate(['/home']);
        });
      }else{
      }
    });
  }
}
