import { Injectable } from "@angular/core";
import { Observable, Subject } from "rxjs";

@Injectable()
export class DirectoryService {

    
    //Observables
    private subjetDirectory = new Subject<any>();
    setDataDirectory(directory: any) {
        this.subjetDirectory.next(directory);

    }
    getDataDirectory(): Observable<any> {
        return this.subjetDirectory.asObservable();
    }

    private usuarioLog = new Subject<any>()
    setDataUsuario(usuario: any) {
        this.usuarioLog.next(usuario);
    }
    getDataUsuario(): Observable<any> {
        return this.usuarioLog.asObservable();
    }
    
}