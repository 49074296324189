import { CanActivate, Router } from '@angular/router';
import { Injectable, NgZone } from '@angular/core';
import { LogDriveComponent } from './log-drive.component';

@Injectable()
export class LoggedInGuard implements CanActivate {
  constructor(
    private router: Router,
    private LogDriveComponent: LogDriveComponent,
    private zone: NgZone
  ) {}
  canActivate() {
    var isLoggedIn = this.LogDriveComponent.isSignedIn;
    if (!isLoggedIn) {
      this.zone.run(() => {
        this.router.navigate(['/signin']);
        });
    }
    return isLoggedIn;
  }
}
