import { BrowserModule } from '@angular/platform-browser';
import { APP_INITIALIZER, NgModule } from '@angular/core';
import { AppComponent } from './app.component';
import { StartComponent } from './start/start.component';
import { LogDriveComponent } from './log-drive/log-drive.component';
import { AppRepository } from './log-drive/repositore/app.repository';
import { UserRepository } from './log-drive/repositore/user.repository';
import { DocumentsComponent } from './Components/documents/documents.component';
import { BreadCrumbComponent } from './Components/breadcrumb/breadcrumb.component';
import { BreadCrumbItemComponent } from './Components/breadcrumb/breadcrumbitem/breadcrumbitem.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import {MatBottomSheetModule} from '@angular/material/bottom-sheet';
import {MatIconModule} from '@angular/material/icon';
import {MatMenuModule} from '@angular/material/menu';
import { MatToolbarModule } from '@angular/material/toolbar';
import { BreadCrumbSession } from './infrastructure/breadcrumb.session';
import { DirectoryService } from './services/directiry.service';
import { SignInComponent } from './log-drive/signin/signin.component';
import { LoggedInGuard } from './log-drive/loggedInGuard';
import { AppRoutingModule } from './app-routing.module';

export function initGapi(gapiSession: LogDriveComponent) {
  return () => gapiSession.initClient();
}

@NgModule({
  declarations: [
    AppComponent,
    StartComponent,
    DocumentsComponent,
    BreadCrumbComponent,
    BreadCrumbItemComponent,
    SignInComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    
    MatBottomSheetModule,
    MatIconModule,
    MatMenuModule,
    MatToolbarModule
  ],
  providers: [
    { provide: APP_INITIALIZER, useFactory: initGapi, deps: [LogDriveComponent], multi: true  },

    LogDriveComponent,
    AppRepository,
    UserRepository,
    BreadCrumbSession,
    DirectoryService,
    LoggedInGuard

  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
