<!-- Toolbar -->
<div class="toolbar row" role="banner">
  <div class="img-logo-gcp col">
    <a routerLink="/home" role="button" aria-disabled="true">
      <img class="log-gcp" src="../assets/Img/logo_balazo.png" />
    </a>
  </div>
  <div>
    <span style="text-align: center" class="logo col-6">
      <h2 class="title-principal">SISTEMA DE INFORMACIÓN</h2>
    </span>
  </div>
  <div class="col">
    <button (click)="signIn()" type="button" class="btn btn-primary" *ngIf=" users === ''">Iniciar Sesión </button>
    <button (click)="signOut()" type="button" class="btn btn-success" *ngIf=" users !== ''">Cerrar Sesión </button>
    <h4 class="title-user">{{ users }}</h4>
  </div>
</div>

<!--content-->
<div>
  <router-outlet></router-outlet>
</div>

<!-- Footer -->
<footer class="footer">
  <div class="footer-info">
    <div class="footer-info-colum-1">
      <div><h2>SIC</h2></div>
      <div><p>Sistema de Información Corporativa</p></div>
    </div>
    <div class="footer-separator"></div>
    <div class="footer-info-colum-2">
      <div><h2>GCP</h2></div>
      <div><p>Grupo Corporativo Papelera</p></div>
    </div>
  </div>
</footer>
